import React, { memo } from 'react'

import styled from 'styled-components'

import ChevronRight from '@interco/icons/orangeds/XL/chevron-right'

import { Text } from '../../Text'
import * as S from './styles'
import { ItemProps, ListItemsProps, Option } from './types'

const ArrowRight = styled(ChevronRight)`
  path {
    stroke: var(--select-bottom-sheet-primary-icon-color);
  }
`

export const ListItem: React.FC<ItemProps> = ({ value, text, onClick }: ItemProps) => (
  <S.Item
    onClick={() => {
      onClick(value)
    }}
  >
    <Text variant="body-3" as="span">
      {text}
    </Text>

    <div>
      <ArrowRight height={24} width={24} data-testid="arrow_right_icon" />
    </div>
  </S.Item>
)

const Component = ({
  listOptions,
  handleSelectOption,
  parentFixedHeight,
  isUsingFilter,
}: ListItemsProps) => (
  <S.SelectList parentFixedHeight={parentFixedHeight} isUsingFilter={isUsingFilter}>
    {listOptions.map((option: Option) => (
      <ListItem
        key={option.value}
        text={option.text}
        value={option.value}
        onClick={() => handleSelectOption(option)}
      />
    ))}
  </S.SelectList>
)

export const ListOptions = memo(Component)
