import styled, { css } from 'styled-components'

import { isMobile } from '@interco/lib-util'

type StyledContainerProps = {
  disabled: boolean
}

type StyledTextProps = StyledContainerProps & {
  error: boolean
  success: boolean
}

type StyledBoxProps = StyledTextProps

export type StyledInputProps = StyledContainerProps & {
  hasIconRight: boolean
  hasIconLeft: boolean
}

const textModifiers = {
  success: () => css`
    color: var(--input-success-label-text-color);
  `,
  error: () => css`
    color: var(--input-error-label-text-color);
  `,
  disabled: () => css`
    color: var(--input-disabled-label-text-color);
    -webkit-text-fill-color: var(--input-disabled-label-text-color);
  `,
}

type typeStatus = 'SUCCESS' | 'ERROR' | 'DISABLED'

export const placeholder = (mode: typeStatus) => {
  const color = {
    SUCCESS: () =>
      css`
        color: var(--input-success-label-text-color);
        font-family: Inter;
        font-size: var(--input-placeholder-font-size);
      `,
    ERROR: () =>
      css`
        color: var(--input-error-label-text-color);
        font-family: Inter;
        font-size: var(--input-placeholder-font-size);
      `,
    DISABLED: () =>
      css`
        color: var(--input-disabled-label-text-color);
        font-family: Inter;
        font-size: var(--input-placeholder-font-size);
      `,
  }
  return color[mode]
}
export const variationPlaceholder = (mode: typeStatus) => css`
  ::placeholder {
    ${placeholder(mode)}
  }
  ::-webkit-input-placeholder {
    ${placeholder(mode)}
  }
  :-ms-input-placeholder {
    ${placeholder(mode)}
  }
  :focus {
    ::placeholder {
      ${placeholder(mode)}
    }
    ::-webkit-input-placeholder {
      ${placeholder(mode)}
    }
    :-ms-input-placeholder {
      ${placeholder(mode)}
    }
  }
`

export const boxModifiers = {
  success: () => css`
    background-color: var(--input-success-input-background-color);
    input,
    & {
      ${variationPlaceholder('SUCCESS')}
    }
  `,
  error: () => css`
    background-color: var(--input-error-input-background-color);
    input,
    & {
      ${variationPlaceholder('ERROR')}
    }
  `,
}

export const levelColorMap: Record<number, string> = {
  1: 'var(--error500)',
  2: 'var(--alert500)',
  3: 'var(--orange500)',
  4: 'var(--success500)',
}

export const Title = styled.div`
  display: flex;
  margin-bottom: 4px;
`

export const Label = styled.label<StyledTextProps>`
  ${({ error, success, disabled }) => css`
    font-size: var(--input-label-font-size);
    line-height: 16px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 0;
    font-family: Inter;
    color: var(--input-label-text-color);
    ${disabled && textModifiers.disabled()}
    ${error && textModifiers.error()}
    ${success && textModifiers.success()}
  `}
`

const hideTimeInputDropdown = css`
  input[type='time']::-webkit-inner-spin-button,
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`

export const Box = styled.div<StyledBoxProps>`
  ${({ error, success }) => css`
    height: 48px;
    background: var(--input-background-color);
    border-radius: var(--input-border-radius);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    ${error && boxModifiers.error()}
    ${success && boxModifiers.success()}

    ${!isMobile() && hideTimeInputDropdown}
  `}
`

export const inputStyle = ({ hasIconRight, hasIconLeft, disabled }: StyledInputProps) => css`
  background: transparent;
  flex: 1;
  height: 100%;
  padding: 16px;
  font-weight: 700;
  font-size: var(--input-font-size);
  line-height: var(--input-font-size);
  font-family: Inter;
  color: var(--input-font-color);
  caret-color: var(--input-caret-color);

  ${disabled && textModifiers.disabled()}
  ${hasIconRight && 'padding-right: 8px;'}
  ${hasIconLeft && 'padding-left: 8px;'}
`

export const Input = styled.input<StyledInputProps>`
  ${({ hasIconRight, hasIconLeft, disabled }) => css`
    ${inputStyle({ hasIconLeft, hasIconRight, disabled })}
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    :focus {
      ::placeholder {
        color: var(--typography500);
      }
    }

    [type='number'] {
      -moz-appearance: textfield;
    }

    width: 100%;
  `}
`

export const IconLeft = styled.div`
  padding: 12px 0 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const IconRight = styled.div.attrs(() => ({
  'data-testid': 'right-icon',
}))`
  padding: 12px 16px 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const InfoText = styled.span<StyledTextProps>`
  ${({ error, success, disabled }) => css`
    font-family: Inter;
    font-size: var(--input-info-font-size);
    text-align: right;
    line-height: 16px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--input-info-text-color);
    ${disabled && textModifiers.disabled()}
    ${error && textModifiers.error()}
    ${success && textModifiers.success()}
  `}
`

export const Container = styled.div<StyledContainerProps>`
  ${({ disabled }) => css`
    display: inline-flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 20px;
    ${disabled && 'pointer-events: none;'}
  `}
`

export const PasswordLevelBlock = styled.div<{ level: number }>`
  height: 2px;
  width: 24px;
  background-color: ${({ level }) =>
    level >= 1 && level <= 4 ? levelColorMap[level] : 'var(--gray200)'};
  border-radius: 1px;
  margin-left: 8px;
`

export const PasswordLevelContainer = styled.div`
  display: flex;
  align-items: flex-end;
  align-items: center;
  justify-content: end;
  max-width: 128px;
  min-width: 128px;
  margin-right: 4px;
`
