import React, { useMemo, useEffect } from 'react'

import { DEFAULT_PASSWORD_RULES } from '@interco/lib-util'
import Clear from '@interco/icons/orangeds/SM/clear'

import { Text } from '../Text'
import * as S from './styles'
import { StrongPasswordProps } from './types'

export const RULE_ICON_SIZE = 14
export const PASSWORD_VALID_COLOR = 'var(--success500)'
export const PASSWORD_INVALID_COLOR = 'var(--gray300)'
export const MIN_STRENGTH_LEVEL = 0
export const MAX_STRENGTH_LEVEL = 4
export const MIN_STRENGTH_LEVEL_VALUE = 100 / MAX_STRENGTH_LEVEL

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-strongpassword-since-v3-5-0--example)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import styled from 'styled-components'
 *
 * import HideIcon from '@interco/icons/orangeds/XL/hide'
 * import ShowIcon from '@interco/icons/orangeds/XL/show'
 * import { StrongPassword, MAX_STRENGTH_LEVEL } from '@interco/inter-ui/components/StrongPassword'
 * import { Input } from '@interco/inter-ui/components/Input'
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * const iconProps = { width: 24, height: 24, color: 'var(--gray400)' }
 *
 * const InputContainer = styled.div`
 *   display: flex;
 *   align-items: center;
 *   width: 100%;
 * `
 *
 * export const MyPage = () => {
 *   const [password, setPassword] = useState('')
 *   const [showPassword, setShowPassword] = useState(false)
 *   const [passwordLevel, setPasswordLevel] = useState(0)
 *
 *   return (
 *     <div>
 *       <StrongPassword
 *         password={password}
 *         onChangePasswordStrength={(passwordStrength) => {
 *           setPasswordLevel(passwordStrength)
 *         }}
 *       />
 *
 *       <InputContainer>
 *         <Input
 *           id="password"
 *           label="Nova Senha"
 *           placeholder="Digite sua senha"
 *           type={showPassword ? 'text' : 'password'}
 *           iconRight={
 *             showPassword ? (
 *               <Show onClick={() => setShowPassword(false)} />
 *             ) : (
 *               <Hide onClick={() => setShowPassword(true)} />
 *             )
 *           }
 *           value={password}
 *           onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
 *           style={{ margin: '0 20px' }}
 *         />
 *
 *         <Button disabled={!(passwordLevel === MAX_STRENGTH_LEVEL)}>Criar Senha</Button>
 *       </InputContainer>
 *     </div>
 *   )
 * }
 * ```
 */
export const StrongPassword = ({
  password = '',
  rules = DEFAULT_PASSWORD_RULES,
  onChangePasswordStrength,
}: StrongPasswordProps) => {
  const hasRule = useMemo(() => rules && rules.length > 0, [rules])
  const totalValidRules = useMemo(
    () =>
      rules.reduce(
        (validRuleCount, { isValid }) => (isValid(password) ? validRuleCount + 1 : validRuleCount),
        0,
      ),
    [password, rules],
  )

  useEffect(() => {
    const validPercentage = Math.round((totalValidRules / rules.length) * 100)
    const passwordStrengthLevel = Math.round(validPercentage / MIN_STRENGTH_LEVEL_VALUE)

    onChangePasswordStrength?.(passwordStrengthLevel)
  }, [totalValidRules, onChangePasswordStrength, rules.length, password])

  return (
    <S.Container>
      {hasRule && (
        <S.RulesContainer>
          {rules.map(({ description, isValid }) => (
            <S.Rule data-testid="rule" key={description}>
              <Clear
                height={RULE_ICON_SIZE}
                width={RULE_ICON_SIZE}
                color={isValid(password) ? PASSWORD_VALID_COLOR : PASSWORD_INVALID_COLOR}
              />
              <Text variant="body-3" colorWeight={500}>
                {description}
              </Text>
            </S.Rule>
          ))}
        </S.RulesContainer>
      )}
    </S.Container>
  )
}
