import styled, { css } from 'styled-components'

import { Text as UIText, TextProps } from '@/components/Text'

import { ModifiersProps, StyledTagProps } from './types'

export const TagText = styled(UIText)<
  TextProps & Pick<StyledTagProps, 'theme' | 'colorText' | 'intensity'>
>`
  ${({ colorText, theme, intensity }) => css`
    color: ${colorText || `var(--tag-${theme}-text-${intensity}-color)`};
  `}
`

const modifiers = ({ theme, intensity }: ModifiersProps) =>
  css`
    background-color: var(--tag-${theme}-background-${intensity}-color);
  `

export const Container = styled.div<{ hasOnClick: boolean }>`
  display: flex;

  ${({ hasOnClick }) => hasOnClick && 'cursor: pointer;'}
`

export const Tag = styled.span<StyledTagProps>`
  ${({ size, theme, intensity, color }: StyledTagProps) => css`
    padding: ${size === 'small' ? '2px 4px' : '8px 16px'};
    margin: 0 4px;
    border-radius: var(--radiiSm);
    white-space: nowrap;

    ${color &&
    css`
      background-color: ${color};
    `}

    ${theme && intensity && !color && modifiers({ theme, intensity })}
  `}
`
