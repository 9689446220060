import React, { PropsWithChildren } from 'react'

import { Text } from '../Text'
import * as S from './styles'
import { TimelineItemProps } from './types'

export const TimelineItem = ({
  title,
  icon,
  type = 'Success',
  hasVerticalBar = true,
  children,
  ...attrs
}: PropsWithChildren<TimelineItemProps>) => (
  <S.TimelineItemContainer {...attrs}>
    <div style={{ minWidth: '20px' }}>
      {icon ? (
        <S.CustomIconContainer type={type}>{icon}</S.CustomIconContainer>
      ) : (
        <S.Dot type={type} />
      )}
      {hasVerticalBar && <S.VerticalBar type={type} />}
    </div>

    <div style={{ paddingLeft: '15px', paddingBottom: '15px' }}>
      {title && (
        <Text
          color="typography"
          colorWeight={type === 'Disabled' ? 300 : 500}
          variant="caption-1"
          bold
        >
          {title}
        </Text>
      )}

      <Text color="typography" colorWeight={type === 'Disabled' ? 200 : 400} variant="caption-1">
        <p>{children}</p>
      </Text>
    </div>
  </S.TimelineItemContainer>
)
