import React, { memo, PropsWithChildren } from 'react'

import Exit from '@interco/icons/orangeds/MD/exit'

import { Text } from '../Text'
import { Link } from '../Link'
import { Spacing } from '../Spacing'
import * as S from './styles'
import { AlertProps, AlertType } from './types'

export type { AlertProps, AlertType } from './types'

const ALERT_ICON_MAPPER: Record<AlertType, JSX.Element> = {
  success: <S.SuccessIconColor data-testid="icon" height={16} width={16} />,
  warning: <S.WarningIconColor height={16} width={16} />,
  error: <S.ErrorIconColor height={16} width={16} />,
  grayscale: <S.GrayscaleIconColor height={16} width={16} />,
  info: <S.InfoIconColor height={16} width={16} />,
}

const Icon = ({
  hideIcon,
  type = 'success',
  customIcon,
}: Pick<AlertProps, 'hideIcon' | 'type' | 'customIcon'>) => {
  if (hideIcon) return null
  if (customIcon) return <S.IconContainer>{customIcon}</S.IconContainer>
  return <S.IconContainer>{ALERT_ICON_MAPPER[type]}</S.IconContainer>
}

const AlertComponent = ({
  title,
  type,
  customBackgroundColor,
  customIcon,
  children,
  hrefTitle,
  hideIcon = false,
  showCloseButton,
  onClose,
  ...props
}: PropsWithChildren<AlertProps>) => (
  <S.Container type={type} customBackgroundColor={customBackgroundColor} {...props}>
    <Icon hideIcon={hideIcon} type={type} customIcon={customIcon} />

    <S.AlertContent>
      {title && (
        <>
          {hrefTitle ? (
            <Spacing mb="xxxs">
              <Link variant="caption-1" color="primary" href={hrefTitle} bold>
                {title}
              </Link>
            </Spacing>
          ) : (
            <Text
              color="typography"
              colorWeight={500}
              variant="caption-1"
              bold
              style={{ display: 'block', marginBottom: '4px' }}
            >
              {title}
            </Text>
          )}
        </>
      )}

      <Text color="typography" colorWeight={500} variant="caption-1" style={{ display: 'block' }}>
        {children}
      </Text>
    </S.AlertContent>

    {showCloseButton && (
      <S.CloseButton onClick={onClose}>
        <Exit height={16} width={16} color="var(--gray400)" />
      </S.CloseButton>
    )}
  </S.Container>
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-alert-since-v2-0-40--primary)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8557-3671&t=ko4sUgGMYIfW97i9-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Alert } from '@interco/inter-ui/components/Alert'
 *
 * export const MyPage = () => {
 *   return (
 *     <Alert
 *       title="Alert Title"
 *       type="success"
 *     >
 *       Alert Description
 *     </Alert>
 *   )
 * }
 * ```
 */
export const Alert = memo(AlertComponent)
