import styled, { css } from 'styled-components'

import { GridProps, GridItemProps } from './types'

export const Grid = styled.div<GridProps>`
  box-sizing: border-box;
  display: grid;

  ${({
    templateColumns,
    templateRows,
    templateAreas,
    template,
    gap,
    columnGap,
    rowGap,
    autoColumns,
    autoRows,
    autoFlow,
    grid,
    justifyContent,
    alignContent,
    justifyItems,
    alignItems,
  }) => css`
    grid-template-columns: ${templateColumns};
    grid-template-rows: ${templateRows};
    grid-template-areas: ${templateAreas};
    grid-template: ${template};
    gap: ${gap};
    column-gap: ${columnGap};
    row-gap: ${rowGap};
    grid-auto-columns: ${autoColumns};
    grid-auto-rows: ${autoRows};
    grid-auto-flow: ${autoFlow};
    grid: ${grid};
    justify-content: ${justifyContent};
    align-content: ${alignContent};
    justify-items: ${justifyItems};
    align-items: ${alignItems};
  `}
`

export const GridItem = styled.div<GridItemProps>`
  ${({
    column,
    columnStart,
    columnEnd,
    row,
    rowStart,
    rowEnd,
    area,
    justifySelf,
    alignSelf,
  }) => css`
    grid-column: ${column};
    grid-column-start: ${columnStart};
    grid-column-end: ${columnEnd};
    grid-row: ${row};
    grid-row-start: ${rowStart};
    grid-row-end: ${rowEnd};
    grid-area: ${area};
    justify-self: ${justifySelf};
    align-self: ${alignSelf};
  `}
`
