import React, { useEffect } from 'react'

import { Text } from '../../Text'
import { BottomSheet, BottomSheetProps } from '../bottom-sheet'
import * as S from './styles'
import { ISafeIcon } from './Icon'

export type ISafeBottomSheetProps = {
  authenticate: () => Promise<void>
  onComplete: (success: boolean) => void
  title?: string | React.ReactNode
  description?: string | React.ReactNode
} & Pick<BottomSheetProps, 'onClose'>

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/bottom-sheet-isafebottomsheet-since-v2-3-0--default)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/1JFZTVxalrwvmUHCWBf2Pt/DS-%2F-Components-%2F-Mobile-%26-App?node-id=5914-6885&t=eCMf3l9IHUqvtrlQ-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useCallback, useState } from 'react'
 *
 * import { Button } from '@interco/inter-ui/components/Button'
 * import { ISafeBottomSheet } from '@interco/inter-ui/components/BottomSheet/ISafe'
 *
 * export const MyPage = () => {
 *   const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
 *
 *   const iSafeAuthenticate = useCallback(
 *     (): Promise<void> =>
 *       // Função que fará a autenticação via i-safe.
 *       // A função deve retornar uma promise.
 *       Promise.resolve(),
 *     [],
 *   )
 *
 *   const onAuthenticateComplete = useCallback((status: boolean) => {
 *     if (status) {
 *       // Caso a autenticação tenha sucesso fazer algo aqui
 *     }
 *
 *     // Caso a falhe a autenticação, fazer algo aqui
 *   }, [])
 *
 *   return (
 *     <>
 *       <Button onClick={() => setShowBottomSheet(true)}>Open iSafe Bottom Sheet</Button>
 *
 *       {showBottomSheet && (
 *         <ISafeBottomSheet
 *           onClose={() => setShowBottomSheet(false)}
 *           authenticate={iSafeAuthenticate}
 *           onComplete={onAuthenticateComplete}
 *         />
 *       )}
 *     </>
 *   )
 * }
 * ```
 */
export const ISafeBottomSheet = ({
  title,
  description,
  authenticate,
  onComplete,
  onClose,
}: ISafeBottomSheetProps) => {
  useEffect(() => {
    authenticate()
      .then(() => onComplete(true))
      .catch(() => onComplete(false))
      .finally(() => onClose())
  }, [authenticate, onComplete, onClose])

  return (
    <BottomSheet onClose={onClose}>
      <S.Container>
        <ISafeIcon />

        {title && React.isValidElement(title) ? (
          title
        ) : (
          <Text variant="headline-h3" semiBold>
            {title || 'Autenticação'}
          </Text>
        )}

        {description && React.isValidElement(description) ? (
          description
        ) : (
          <Text variant="body-3">
            {description || 'Para sua segurança estamos autenticando essa operação com o i-safe.'}
          </Text>
        )}
      </S.Container>
    </BottomSheet>
  )
}
