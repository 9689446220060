import React from 'react'

import * as S from './styles'
import { TimelineItem } from './timelineItem'

export type { TimelineItemProps, TimelineType } from './types'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-timeline-since-v2-0-36--single)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/1JFZTVxalrwvmUHCWBf2Pt/DS-%2F-Components-%2F-Mobile-%26-App?t=GJjZdsjiFDJOUQOy-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Timer } from '@interco/inter-ui/components/Timer'
 *
 * export const MyPage = () => {
 *   return <div>CODE_EXAMPLE_HERE</div>
 * }
 * ```
 */
const Timeline = ({ children: childrenProp, ...attrs }: PropsWithRequiredChildren) => {
  const itemsCount = React.Children.count(childrenProp)

  const children = React.Children.map(childrenProp, (child, index) => {
    if (React.isValidElement(child)) {
      const childProps = {
        hasVerticalBar: index !== itemsCount - 1,
      }
      return React.cloneElement(child, childProps)
    }
    return child
  })

  return <S.TimelineContainer {...attrs}>{children}</S.TimelineContainer>
}

Timeline.Item = TimelineItem
export { Timeline }
