import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const Header = styled.div`
  margin-bottom: 10px;
`

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
// 28px -> bottomSheet bar height
// 10px 24px -> SelectList parent padding
// 96px 10px -> filter height
export const SelectList = styled.ul<{ parentFixedHeight?: string; isUsingFilter?: boolean }>`
  ${({ parentFixedHeight = '462px', isUsingFilter = false }) => css`
    max-height: calc(${parentFixedHeight} - 62px ${isUsingFilter && '- 106px'});
    overflow: auto;

    li:first-child {
      border: none;
    }
  `}
`

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid var(--select-bottom-sheet-item-border-color);

  > div {
    margin-left: 5px;
  }
`
