import React, { useState, FormEvent, useEffect, memo, useCallback } from 'react'

import styled from 'styled-components'

import ChevronDown from '@interco/icons/orangeds/XL/chevron-down'

import { BottomSheet } from '..'

import { Input } from '../../Input'
import { Loading } from '../../Loading'
import * as S from './styles'
import { Header } from './header'
import { ListOptions } from './listOptions'
import { Option, SelectBottomSheetProps } from './types'

export type {
  Option,
  SelectBottomSheetProps,
  HeaderProps,
  ItemProps,
  ListItemsProps,
} from './types'

const Component = ({
  label,
  options,
  isUsingFilter = false,
  placeholderFilter,
  isLoading = false,
  fixedHeight,
  value,
  onChange,
  ...props
}: SelectBottomSheetProps) => {
  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false)
  const [filteredValue, setFilteredValue] = useState<string>('')
  const [listFilteredOption, setListFilteredOption] = useState<Option[]>(options)

  const ArrowDownIcon = styled(ChevronDown)`
    path {
      stroke: ${props.disabled
        ? 'var(--select-bottom-sheet-disabled-icon-color)'
        : 'var(--select-bottom-sheet-primary-icon-color)'};
    }
  `

  const Icon = useCallback(
    () =>
      isLoading ? (
        <Loading height={24} width={24} color="var(--select-bottom-sheet-primary-icon-color)" />
      ) : (
        <ArrowDownIcon height={24} width={24} />
      ),
    [ArrowDownIcon, isLoading],
  )

  const handleDisplayBottomSheet = useCallback(() => {
    setOpenBottomSheet(!openBottomSheet)
  }, [openBottomSheet])

  const handleChangeFilter = useCallback((event: FormEvent<HTMLInputElement>) => {
    setFilteredValue(event.currentTarget.value)
  }, [])

  const handleCleanFilter = useCallback(() => {
    setFilteredValue('')
  }, [])

  const handleSelectOption = useCallback(
    (option: Option) => {
      if (!props.disabled) {
        onChange(option)
        handleDisplayBottomSheet()
      }
    },
    [handleDisplayBottomSheet, onChange, props.disabled],
  )

  useEffect(() => {
    if (filteredValue) {
      const filteredList = options.filter((option: Option) =>
        option.text.toLocaleLowerCase().includes(filteredValue.toLocaleLowerCase()),
      )
      setListFilteredOption(filteredList)
    } else {
      setListFilteredOption(options)
    }
  }, [filteredValue, options])

  return (
    <S.Container>
      <Input
        label={label}
        onFocus={(element) => element.target.blur()}
        onClick={handleDisplayBottomSheet}
        value={value?.text}
        iconRight={<Icon />}
        onClickIconRight={handleDisplayBottomSheet}
        readOnly
        {...props}
      />

      {openBottomSheet && (
        <BottomSheet onClose={handleDisplayBottomSheet} noSwipe fixedHeight={fixedHeight}>
          {isUsingFilter && (
            <Header
              label={label}
              placeholderFilter={placeholderFilter}
              filteredValue={filteredValue}
              handleChangeFilter={handleChangeFilter}
              handleCleanFilter={handleCleanFilter}
              handleOnClose={handleDisplayBottomSheet}
            />
          )}

          <ListOptions
            isUsingFilter={isUsingFilter}
            parentFixedHeight={fixedHeight}
            listOptions={listFilteredOption}
            handleSelectOption={handleSelectOption}
          />
        </BottomSheet>
      )}
    </S.Container>
  )
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/bottom-sheet-selectbottomsheet-since-v2-6-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/1JFZTVxalrwvmUHCWBf2Pt/DS-%2F-Components-%2F-Mobile-%26-App?node-id=5914-6885&t=eCMf3l9IHUqvtrlQ-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { SelectBottomSheet, Option } from '@interco/inter-ui/components/BottomSheet/SelectBottomSheet'
 *
 * export const MyPage = () => {
 *   const [value, setValue] = useState<Option>()
 *
 *   const handleSelectValue = useCallback((currentValue: Option) => {
 *     setValue(currentValue)
 *   }, [])
 *
 *   const optionsList = useMemo(() => ([
 *     { value: 1, text: 'Opção 01' },
 *     { value: 2, text: 'Opção 02' },
 *   ]), [])
 *
 *   return (
 *     <SelectBottomSheet
 *       id="select"
 *       label="My Label"
 *       placeholder="My Placeholder"
 *       isUsingFilter
 *       options={optionsList}
 *       onChange={handleSelectValue}
 *       value={value}
 *     />
 *   )
 * }
 * ```
 */
export const SelectBottomSheet = memo(Component)
