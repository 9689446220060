import { IFullColors, IHalfColors } from "./inter"

export interface ICommonColors {
  opacity: IFullColors
  auxiliary: {
    strawberry: IFullColors
    pitaya: IFullColors
    grape: IFullColors
    mirtilo: IFullColors
    raspberry: IFullColors
    avocado: IFullColors
    lime: IFullColors
    pear: IFullColors
    toranja: IFullColors
    coconut: IFullColors
  }
  feedback: {
    success: IHalfColors
    warning: IHalfColors
    error: IHalfColors
  }
  elevation: {
    theme: string,
    A100: string
    A200: string
  }
}


export const commonLight: ICommonColors = {

  opacity: {
    A500: 'rgba(245,246,250,0.80)',
    A400: 'rgba(245,246,250,0.64)',
    A300: 'rgba(245,246,250,0.48)',
    A200: 'rgba(245,246,250,0.16)',
    A100: 'rgba(245,246,250,0.08)'
  },

  auxiliary: {
    strawberry: {
      A500: '#D32F2F',
      A400: '#EF5350',
      A300: '#EF9A9A',
      A200: '#FFCDD2',
      A100: '#FFEBEE'
    },

    pitaya: {
      A500: '#C2185B',
      A400: '#EC407A',
      A300: '#F48FB1',
      A200: '#F8BBD0',
      A100: '#FCE4EC'
    },
    grape: {
      A500: '#512DA8',
      A400: '#7E57C2',
      A300: '#B39DDB',
      A200: '#D1C4E9',
      A100: '#EDE7F6'
    },

    mirtilo: {
      A500: '#303F9F',
      A400: '#5C6BC0',
      A300: '#9FA8DA',
      A200: '#C5CAE9',
      A100: '#E8EAF6'
    },

    raspberry: {
      A500: '#0277BD',
      A400: '#039BE5',
      A300: '#4FC3F7',
      A200: '#B3E5FC',
      A100: '#E1F5FE'
    },

    avocado: {
      A500: '#00796B',
      A400: '#26A69A',
      A300: '#80CBC4',
      A200: '#B2DFDB',
      A100: '#DDF0EF'
    },

    lime: {
      A500: '#33691E',
      A400: '#7CB342',
      A300: '#AED581',
      A200: '#DCEDC8',
      A100: '#F1F8E9'
    },

    pear: {
      A500: '#9E9D24',
      A400: '#C0CA33',
      A300: '#DCE775',
      A200: '#F0F4C3',
      A100: '#F9FBE7'
    },

    toranja: {
      A500: '#D84315',
      A400: '#FF7043',
      A300: '#FFAB91',
      A200: '#FFCCBC',
      A100: '#FBE9E7'
    },

    coconut: {
      A500: '#5D4037',
      A400: '#795548',
      A300: '#A1887F',
      A200: '#D7CCC8',
      A100: '#EFEBE9'
    },
  },

  feedback: {
    success: {
      A500: '#00AA4F',
      A100: '#E9F8F0'
    },

    warning: {
      A500: '#FFC700',
      A100: '#FFF7D9'
    },

    error: {
      A500: '#F56A50',
      A100: '#FEF0ED'
    },
  },

  elevation: {
    theme: '#FFFFFF',
    A100: '#F5F6FA',
    A200: '#FFFFFF'
  },
}

export const commonDark: ICommonColors = {

  opacity: {
    A500: 'rgba(22,22,22,0.80)',
    A400: 'rgba(22,22,22,0.64)',
    A300: 'rgba(22,22,22,0.48)',
    A200: 'rgba(22,22,22,0.16)',
    A100: 'rgba(22,22,22,0.08)'
  },

  auxiliary: {

    strawberry: {
      A500: '#EF5350',
      A400: '#BC4644',
      A300: '#883838',
      A200: '#572B2C',
      A100: '#332224'
    },

    pitaya: {
      A500: '#EC407A',
      A400: '#C43969',
      A300: '#973154',
      A200: '#67293F',
      A100: '#3B212C'
    },

    grape: {
      A500: '#9575CD',
      A400: '#6C4CA4',
      A300: '#563F7F',
      A200: '#3E3157',
      A100: '#2B2537'
    },

    mirtilo: {
      A500: '#7986CB',
      A400: '#505DA3',
      A300: '#424B7E',
      A200: '#323857',
      A100: '#262836'
    },

    raspberry: {
      A500: '#039BE5',
      A400: '#0A75A9',
      A300: '#10567A',
      A200: '#163A4E',
      A100: '#192A34'
    },

    avocado: {
      A500: '#26A69A',
      A400: '#237A72',
      A300: '#205955',
      A200: '#1F4140',
      A100: '#1E3131'
    },

    lime: {
      A500: '#689F38',
      A400: '#4A6C2E',
      A300: '#3B5229',
      A200: '#2C3823',
      A100: '#242921'
    },

    pear: {
      A500: '#9E9D24',
      A400: '#6F6F22',
      A300: '#4B4A20',
      A200: '#31311F',
      A100: '#29291F'
    },

    toranja: {
      A500: '#EA5527',
      A400: '#B1411E',
      A300: '#88371E',
      A200: '#5D2C1E',
      A100: '#34221E'
    },

    coconut: {
      A500: '#A28176',
      A400: '#664A40',
      A300: '#4B3833',
      A200: '#382D2B',
      A100: '#282323'
    },

  },

  feedback: {
    success: {
      A500: '#03A74F',
      A100: '#1A2A23'
    },

    warning: {
      A500: '#FAC405',
      A100: '#302B1C'
    },

    error: {
      A500: '#F5554D',
      A100: '#302122'
    },
  },

  elevation: {
    theme: '#252628',
    A100: '#353539',
    A200: '#616368'
  }
}


