import React, { memo } from 'react'

import RadioGroupContext from '../RadioGroup/context'
import * as S from './styles'

export type RadioProps = Omit<DefaultHTMLAttrs<HTMLInputElement>, 'color'> & {
  id: string
  value: string
  name?: string
  checked?: boolean
  label?: string | React.ReactNode
  disabled?: boolean
  reversed?: boolean
  size?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Label = ({ id, reversed, label }: Partial<RadioProps>) => (
  <S.Label htmlFor={id} reversed={reversed}>
    {label}
  </S.Label>
)

const DEFAULT_RADIO_SIZE = 32

export const Component = ({
  id,
  value,
  label = '',
  name: nameProp,
  disabled: disabledProp = false,
  checked: checkedProp = false,
  reversed = false,
  onChange,
  style,
  className,
  size = DEFAULT_RADIO_SIZE,
  ...attrs
}: RadioProps) => {
  const radioGroup = React.useContext(RadioGroupContext)

  let name = nameProp
  let checked = checkedProp
  let disabled = disabledProp

  if (radioGroup) {
    name = radioGroup.name
    checked = radioGroup.value === value
    disabled = Boolean(radioGroup.disabled)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }

  return (
    <S.Container disabled={disabled} style={style} className={className}>
      {reversed && label && <Label id={id} reversed={reversed} label={label} />}

      <S.Input
        {...attrs}
        type="radio"
        id={id}
        value={value}
        name={name}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      />

      <S.IconWrapper>
        {checked ? (
          <S.RadioOn height={size} width={size} />
        ) : (
          <S.RadioOff height={size} width={size} />
        )}
      </S.IconWrapper>

      {!reversed && label && <Label id={id} label={label} />}
    </S.Container>
  )
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-radio-since-v1-0-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/1JFZTVxalrwvmUHCWBf2Pt/DS-%2F-Components-%2F-Mobile-%26-App?node-id=4-631&t=NA4uIDFsJjbTemvb-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 * import { Radio } from '@interco/inter-ui/components/Radio'
 *
 * export const MyPage = () => {
 *   const [selected, setSelected] = useState<string>('')
 *
 *   return (
 *     <div>
 *       <Radio
 *         id="label-1"
 *         value="1"
 *         name="label"
 *         checked={selected === '1'}
 *         onChange={() => setSelected("1")}
 *       />

 *       <Radio
 *         id="label-2"
 *         value="2"
 *         name="label"
 *         checked={selected === '2'}
 *         onChange={() => setSelected("2")}
 *       />
 *     </div>
 *   )
 * }
 * ```
 */
export const Radio = memo(Component)
