import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

export const Svg = styled.svg`
  animation: ${rotate} 2s linear infinite;
`

export const Circle = styled.circle`
  opacity: 0.35;
`
