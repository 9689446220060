import styled, { css } from 'styled-components'
// TODO: icon not found in lib @interco/icons
import CheckboxIndeterminateIcon from 'inter-frontend-svgs/lib/orangeds/LD/checkbox-indeterminate'
import CheckboxOnIcon from 'inter-frontend-svgs/lib/orangeds/LD/checkbox-on'
import CheckboxOffIcon from 'inter-frontend-svgs/lib/orangeds/LD/checkbox-off'

import { DEFAULT_CHECKBOX_RADIO_SIZE } from '@/common/constants'

export type StyledCheckboxProps = {
  disabled: boolean
  reversed: boolean
  size: number
}

type ModifiersProps = Partial<StyledCheckboxProps>

const modifiers = {
  disabled: `
    pointer-events: none;
    opacity: 0.5;
  `,
}

export const Input = styled.input`
  opacity: 0;
  position: absolute;
`

export const LabelText = styled.div<ModifiersProps>`
  ${({ reversed }) => css`
    font-family: Inter;
    font-size: 12px;
    color: var(--checkbox-label-text-color);
    margin: ${reversed ? '0 12px 0 0' : '0 0 0 12px'};
  `}
`

export const LabelContainer = styled.label<ModifiersProps>`
  ${({ disabled }) => css`
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 4px 0;
    box-sizing: border-box;
    user-select: none;
    ${disabled && modifiers.disabled}
  `}
`

export const CheckboxIndeterminate = styled(CheckboxIndeterminateIcon)<Size>`
  ${DEFAULT_CHECKBOX_RADIO_SIZE}
  fill: var(--checkbox-indeterminate-color);
`

export const CheckboxOn = styled(CheckboxOnIcon)<Size>`
  ${DEFAULT_CHECKBOX_RADIO_SIZE}
  fill: var(--checkbox-color);
  stroke: var(--checkbox-check-color);
`

export const CheckboxOff = styled(CheckboxOffIcon)<Size>`
  ${DEFAULT_CHECKBOX_RADIO_SIZE}
  fill: var(--checkbox-indeterminate-color);
`
