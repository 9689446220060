import styled, { css } from 'styled-components'

import { TimelineType } from './types'
import { colorByType } from './colors'

type TypeProps = {
  type: TimelineType
}

export const TimelineContainer = styled.ul``

export const TimelineItemContainer = styled.li`
  list-style: none;
  display: flex;
  position: relative;
  margin: 0;
  list-style: none;
`

export const CustomIconContainer = styled.div`
  ${({ type }: TypeProps) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      margin-top: 2px;
      fill: ${colorByType[type]};
      stroke: var(--white100);
      path {
        stroke: var(--white100);
      }
      circle {
        stroke: ${colorByType[type]};
      }
    }
  `}
`

export const Dot = styled.span`
  ${({ type }: TypeProps) => css`
    position: absolute;
    height: 12px;
    width: 12px;
    left: 4px;
    top: 6px;
    background-color: ${colorByType[type]};
    border-radius: 50%;
    display: inline-block;
  `}
`

export const VerticalBar = styled.div`
  ${({ type }: TypeProps) => css`
    position: absolute;
    top: 22px;
    left: 9px;
    background-color: ${colorByType[type]};
    border-radius: 1px;
    width: 2px;
    height: calc(100% - 24px);
  `}
`
