import React from 'react'

import styled, { css } from 'styled-components'

import { commonStyles, DEFAULT_COLOR_WEIGHT } from '../Text/styles'
import { LinkProps } from './types'

const LinkStyledComponent = styled.a<LinkProps>`
  ${(props) => css`
    text-decoration: none;
    cursor: pointer;

    ${commonStyles(props as LinkProps)}

    :hover {
      color: var(--primary500);
    }
  `}
`

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-link-since-2-0-10--example)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/RYPyo16YccXBSelucyi2yM/DS-%2F-Foundations?node-id=62106-9931&t=LxpV7ono9QfKrDtM-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 * import { Link } from '@interco/inter-ui/components/Link'
 *
 * export const MyPage = () => {
 *   return (
 *     <Link
 *       href="https://www.bancointer.com.br"
 *       variant="web-head"
 *     >
 *       Inter
 *     </Link>
 *   )
 * }
 * ```
 */

export const Link = ({
  href,
  target = '_blank',
  variant,
  bold,
  semiBold,
  colorWeight = DEFAULT_COLOR_WEIGHT,
  ...props
}: LinkProps) => (
  <LinkStyledComponent
    {...props}
    variant={variant}
    bold={bold}
    semiBold={semiBold}
    colorWeight={colorWeight}
    target={target}
    href={href}
    rel={target === '_blank' ? 'noopener noreferrer' : ''}
  />
)
