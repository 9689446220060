import React from 'react'

import * as S from './styles'
import { SpacingProps } from './types'

export type { SpacingProps }

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-spacing-since-3-2-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/RYPyo16YccXBSelucyi2yM/DS-%2F-Foundations?node-id=62106-9008&t=qon7f7sL2IQtIfqw-0)
 *
 * Sizes:
 * - `xxxs`: 4px
 * - `xxs`: 8px
 * - `xs`: 16px
 * - `sm`: 24px
 * - `md`: 32px
 * - `lg`: 40px
 * - `xl`: 64px
 * - `xxl`: 96px
 * - `xxxl`: 128px
 *
 * Properties
 * - `m`: margin
 * - `mt`: margin-top
 * - `mr`: margin-right
 * - `mb`: margin-bottom
 * - `ml`: margin-left
 * - `mx`: margin-left, margin-right
 * - `my`: margin-top, margin-bottom
 * - `p`: padding
 * - `pt`: padding-top
 * - `pr`: padding-right
 * - `pb`: padding-bottom
 * - `pl`: padding-left
 * - `px`: padding-left, padding-right
 * - `py`: padding-top, padding-bottom
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Spacing } from '@interco/inter-ui/components/Spacing'
 *
 * export const MyPage = () => {
 *   return <Spacing m="xs" p="xxs">Spacing</Spacing>
 * }
 * ```
 */
export const Spacing = ({ children, ...rest }: SpacingProps) => (
  <S.Wrapper {...rest}>{children}</S.Wrapper>
)
