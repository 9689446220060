import React, { memo } from 'react'

import styled from 'styled-components'

import Search from '@interco/icons/orangeds/XL/search'
import Canceled from '@interco/icons/orangeds/XL/canceled'

import { Text } from '../../Text'
import { Button } from '../../Button'
import { Input } from '../../Input'
import * as S from './styles'
import { HeaderProps } from './types'

const ClearIcon = styled(Canceled)`
  path {
    stroke: var(--select-bottom-sheet-primary-icon-color);
  }
`

const SearchIcon = styled(Search)`
  path {
    stroke: var(--select-bottom-sheet-primary-icon-color);
  }
`

const Component = ({
  label,
  placeholderFilter = 'Pesquisar',
  filteredValue,
  handleChangeFilter,
  handleCleanFilter,
  handleOnClose,
}: HeaderProps) => (
  <S.Header>
    <S.HeaderTitle>
      <Text variant="headline-h3" colorWeight={500} semiBold>
        {label}
      </Text>

      <Button variant="link" onClick={handleOnClose}>
        Fechar
      </Button>
    </S.HeaderTitle>

    <Input
      id="search"
      name="search"
      placeholder={placeholderFilter}
      value={filteredValue}
      onChange={handleChangeFilter}
      iconRight={
        filteredValue ? (
          <ClearIcon
            height={24}
            width={24}
            data-testid="clear_icon"
            color="var(--select-bottom-sheet-primary-icon-color)"
          />
        ) : (
          <SearchIcon
            height={24}
            width={24}
            data-testid="search_icon"
            color="var(--select-bottom-sheet-primary-icon-color)"
          />
        )
      }
      onClickIconRight={filteredValue ? handleCleanFilter : undefined}
      style={{ paddingBottom: 0, width: '100%' }}
    />
  </S.Header>
)

export const Header = memo(Component)
