import React from 'react'

import * as S from './styles'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-loading-since-1-0-0--default)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Loading } from '@interco/inter-ui/components/Loading'
 *
 * export const MyPage = () => {
 *   return <Loading width={50} height={50} />
 * }
 * ```
 */
export const Loading = React.memo(
  ({
    width = 24,
    height = 24,
    color = 'var(--highlight-color)',
    dataTestId = 'loading-icon',
    ...attrs
  }: DefaultSVGProps) => (
    <S.Svg
      viewBox="0 0 24 24"
      fill="none"
      width={width}
      height={height}
      data-testid={dataTestId}
      {...attrs}
    >
      <S.Circle cx="12" cy="12" r="8" stroke={color} strokeWidth="2" />
      <path
        d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C10.5429 20 9.17669 19.6104 8 18.9297"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </S.Svg>
  ),
)
