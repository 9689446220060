import React from 'react'

import * as S from './styles'
import * as Types from './types'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-grid-since-3-5-0--simple)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Grid, GridItem } from '@interco/inter-ui/components/Grid'
 *
 * export const MyPage = () => {
 *   return (
 *     <Grid templateColumns="repeat(6, 1fr)" gap="16px">
 *       {Array.from(Array(6).keys()).map((item) => (
 *         <GridItem key={item + 1}>
 *           <Item>Coluna {item + 1}}</Item>
 *         </GridItem>
 *       ))}
 *     </Grid>
 *   )
 * }
 * ```
 */
export const Grid = ({ children, ...rest }: Types.GridProps) => (
  <S.Grid {...rest}>{children}</S.Grid>
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-grid-since-3-5-0--simple)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Grid, GridItem } from '@interco/inter-ui/components/Grid'
 *
 * export const MyPage = () => {
 *   return (
 *     <Grid templateColumns="repeat(6, 1fr)" gap="16px">
 *       {Array.from(Array(6).keys()).map((item) => (
 *         <GridItem key={item + 1}>
 *           <Item>Coluna {item + 1}}</Item>
 *         </GridItem>
 *       ))}
 *     </Grid>
 *   )
 * }
 * ```
 */
export const GridItem = ({ children, ...rest }: Types.GridItemProps) => (
  <S.GridItem {...rest}>{children}</S.GridItem>
)
